<template>
  <div class='timeline' @mouseover="isMouseOver = true" @mouseleave="isMouseOver = false" builtBy="https://www.philo.co.nz/portfolio/Wordpress_Vue_Timeline">
    <div class='events' :style="{ transform: translationTransform }">
      <div
        v-for="(item, idx) in orderedItems"
        :key="item.id"
        :class="{ selected: selectedIndex === idx }"
        class='event'
        :style="{ 'min-width': `calc(${eventWidth})`}"
        @click="selectedIndex = idx"
      >
        <v-touch @swipeleft="moveLeft" @swiperight="moveRight">
          <div
            class='time'
          >
            {{ item.date }}<br />
            <div class="eventpoint">•</div>
          </div>
          <div
            class='event-body'
          >
            <img :src="item.img" />
            <div class='title'>{{ item.title }}</div>
            <!-- <div class='subheading'>Project value ${{ item.value }}m NZD</div> -->
            <transition name="fade">
              <div v-show="selectedIndex === idx" class='project-link'>
                <a :href="item.link">View Project</a>
              </div>
            </transition>
          </div>
        </v-touch>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Timeline',
  data() {
    return {
      selectedIndex: 0,
      selectedItem: null,
      items: [],
      viewWidth: 0,
      isMouseOver: false,
      cycleSpeed: 3000,
      animationRunning: true
    }
  },
  computed:{
    offsetIndex() {
      var itemsOnPage = 1
      if (this.viewWidth >= 981) {
        itemsOnPage = 5
      }
      else if (this.viewWidth >= 768) {
        itemsOnPage = 3
      }

      if (this.selectedIndex + (itemsOnPage / 2) > this.items.length) {
        return this.items.length - itemsOnPage
      }

      return Math.round(this.selectedIndex - (itemsOnPage / 2))
    },
    eventWidth() {
      const vw = this.viewWidth;

      if (vw >= 981) {
        return '100vW / 5'
      }
      else if (vw >= 768) {
        return '100vW / 3'
      }

      return '90vW'
    },
    translationTransform() {
      var buffer = 0
      if (this.eventWidth == "90vW") {
        buffer = 5
        if (this.selectedIndex == this.items.length - 1) {
          buffer = 5
        }
        else if (this.selectedIndex > 0) {
          buffer = 5
        }
      }

      var idx = this.offsetIndex
      if (idx < 0) {
        idx = 0
      }


      return `translateX( calc((-${idx} * (${this.eventWidth}) + ${buffer}vw)))`
    },
    orderedItems() {
      return this.items
        .sort((a,b)=>{
          if (a.date !== b.date){
            return a.date < b.date ? -1 : 1
          }
          return a.month < b.month ? -1 : 1
        })
    }
  },
  methods: {
    handleResize() {
      this.viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    },
    moveLeft() {
      this.animationRunning = false
      if (this.selectedIndex < this.items.length - 1) {
        this.selectedIndex++
      }
    },
    moveRight() {
      this.animationRunning = false
      if (this.selectedIndex > 0){
        this.selectedIndex--
      }
    },
    progressIndex() {
      if (!this.animationRunning) {
        return
      }
      if (!this.isMouseOver) {
        this.selectedIndex++

        if (this.selectedIndex > this.items.length -1) {
          this.selectedIndex = 0
        }
      }
      
      setTimeout(this.progressIndex, this.cycleSpeed)
    },
    requestItems(pageNo) {
      axios.request({
        url: `${process.env.VUE_APP_BASE_URL}wp-json/wp/v2/timeline_event?_embed&page=${pageNo}`
      }).then(resp => {
        const toAdd = resp.data
          .filter(d => {
            return d.acf
              && d.acf.event_year
              && d.acf.project_value
              && d._embedded && d._embedded["wp:featuredmedia"]
              && d._embedded["wp:featuredmedia"].length
              && d._embedded["wp:featuredmedia"][0].source_url
              && d.title
              && d.title.rendered
              && d.link
          })
          .map(d => {
            return {
              id: d.id,
              date: d.acf.event_year,
              month: d.acf.event_month ? d.acf.event_month : 0,
              img: d._embedded["wp:featuredmedia"][0].source_url,
              title: d.title.rendered,
              value: d.acf.project_value,
              link: d.acf.alt_link ? d.acf.alt_link : d.link
            }
          })
        
        toAdd.forEach(post => {
          this.items.push(post)
        });

        if (toAdd.length == 10) {
          this.requestItems(pageNo + 1)
        }
      })
      .catch(err => {
        if (err?.response?.status == 400 && this.items.length) {
          // happens if the last page was full but there is no 
        }
        else {
          console.log(err)
        }
      })
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    this.requestItems(1);

    setTimeout(this.progressIndex, this.cycleSpeed)
  },

  beforeDestroy () {
  	window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped lang="scss">
.timeline {
  overflow-x: hidden;
  .events {
    display: flex;
    transition: transform 0.4s ease-in-out;
    .event {
      z-index: 2;
      text-align: center;
      transform: z-index 0.5s, min-width 0.5s;
      transition-delay: 0.5s;
      cursor: pointer;
      &.selected {
        flex-grow: 6;
        flex-shrink: 0.3;
      }
      .time {
        flex-grow: 1;
        text-align: center;
        color: #cdcfd0;
        cursor: pointer;
        transition: color 1s;
        margin-bottom: 40px;
        .eventpoint {
          padding-top: 6px;
          font-size: 32px;
          border-bottom: 2px solid black;
          height: 0.53em
        }
      }
      .event-body {
        transition: all 0.5s ease-in-out;
        color: #cdcfd0;
        img {
          transition: all 0.5s ease-in-out;
          transition-delay: 0s;
          transform: scale(1);
          width: 100%;
          filter: grayscale(100%);
        }
        .title {
          font-size: 21px;
          line-height: 20px;
          margin-top: 25px;
        }
        .subheading {
          margin-top: 15px;
          line-height: 20px;
        }
        .project-link {
          padding-top: 15px;
          margin-bottom: 10px;
          a {
            background-color: black;
            color: white;
            text-transform: uppercase;
            border:none;
            padding: 5px 25px 5px 25px;
            font-weight: bold;
            text-decoration: none;
            &:visited, &:hover, &:active {
              text-decoration: none;
            }
          }
        }
      }
      &:hover{
        z-index: 3;
        transition: z-index 0s;
        transition-delay: 0.25s;
        .event-body{
          color:#666666;
          img{
            transform: scale(1.02);
            transition-delay: 0.25s;
          }
        }
      }
      &.selected {
        z-index: 4;
        transition-delay: 0.5s;
        .event-body {
          color: black;
          img {
            transform: scale(1.05);
            transition-delay: 0.5s;
            filter: grayscale(0%);
          }
        }
        
        .time {
          color: black;
        }
      }
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
