<template>
  <div id="app">
    <Timeline />
  </div>
</template>

<script>
import Timeline from './components/Timeline.vue'

export default {
  name: 'app',
  components: {
    Timeline
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}
</style>
